<template>
  <div class="DashboardLifeskillsFavoritesView">
    <div class="scrollContainer">

      <div class="title">
        {{ $t('title') }}
      </div>

      <div class="lifeskills">
        <div
          v-for="lifeskill of lifeskills"
          :key="lifeskill._id"
          class="Item --global-clickable"
          :class="{ 'is-selected': follows(lifeskill.number) }">
          <DashboardLifeskillItem
            class="LifeskillItem"
            :lifeskill-id="lifeskill.number"
            custom-click-handling
            @click.native="toggle(lifeskill.number)" />
          <div class="Item__checkmark">
            <svgicon
              class="svg-icon"
              icon="checkmark" />
          </div>
        </div>
        <div class="Item Item--dummy" />
        <div class="Item Item--dummy" />
      </div>
    </div>

    <Footer
      class="footer"
      :button-label="$t('nextButton')"
      hide-pie
      :selections-count="numberOfSelections"
      @click="gotoNext()" />

    <transition name="fade">
      <LifeskillFavoritesRive
        @stop="handleStop"
        v-if="showLoadingAnimation"
        class="riveAnimation"
        :animation-number="1" />
    </transition>
  </div>
</template>

<translations>
  title: 'Which future skills interest you the most?'
  title_no: 'Hvilke fremtidsferdigheter interesserer deg mest?'

  subtitle: 'Choose your favorites from the 10 essentials'
  subtitle_no: 'Velg favorittene dine fra de 10 anbefalte'

  nextButton: 'Next'
  nextButton_no: 'Neste'
</translations>

<script>
import Footer from './DashboardLifeskillsVoteView/Footer';
import DashboardLifeskillItem from '@/modules/dashboard/components/DashboardLifeskillItem';
import LifeskillFavoritesRive from '../components/LifeskillsFavoritesRive';

export default {
    components: { DashboardLifeskillItem, Footer, LifeskillFavoritesRive },
        computed: {
        numberOfSelections() {
          return this.$store.getters['moduleApp/getMarkedLifeskills'].length;
        },
        lifeskills() {
            return this.$store.state.moduleApp.availableFavorateLifeskillIds.map(lifeskillId => {
                return this.$store.state.moduleApp.lifeskills[lifeskillId];
            });
        }
    },
    data(){
        return {
            showLoadingAnimation: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'DashboardHomeView'){
                vm.showLoadingAnimation = true;
            }
        });
    },
    mounted(){
    },
    methods: {
      follows(ls) {
        return this.$store.getters['moduleApp/follows'](ls);
      },

      handleStop() {
        this.showLoadingAnimation = false;
      },
        toggle(lifeskillId) {
            this.$store.dispatch('moduleApp/toggleLifeskillFollow', { lifeskillId });
        },
        gotoNext() {
            if (this.numberOfSelections === 0) {
                return;
            }
            this.$store.dispatch('moduleApp/markFavoriteLifeskillsSelected');

            this.$router.push({
                path: '/vote'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$selectionColor: #5081ff;

.DashboardLifeskillsFavoritesView {
    background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
    display: flex;
    flex-direction: column;
}

.scrollContainer {
    overflow-y: auto;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-top: 1.5em;
    font-weight: 600;
    color: rgba(white, 0.9);
    font-size: 200%;
    text-align: center;
}

.lifeskills {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4em;
    width: 100%;
}

.LifeskillItem {
    height: (240em / 15);
    // /deep/ .label {
    //   white-space: normal;
    // }
}

.Item {
    position: relative;
    // width: 12em;
    width: (168em / 15);
    // height: (255em / 15);

    flex-shrink: 0;
    margin: 0 1em;
    margin-bottom: 2em;

    &.is-selected {
        .Item__checkmark {
            display: flex;
        }
    }
}
.Item__checkmark {
    display: none;
    position: absolute;
    $size: 1.7em;
    width: $size;
    height: $size;
    top: -$size / 2;
    right: -$size / 2;
    background-color: $selectionColor;
    border-radius: 50%;

    justify-content: center;
    align-items: center;

    .svg-icon {
        fill: white;
        $size: 40%;
        width: $size;
        height: $size;
    }
}
.Item--dummy {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
}

.nextButton:not(.is-enabled) {
    opacity: 0.2;
    cursor: default;
}

.footer {
    background-color: #0a133583;
}

.riveAnimation {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom,#0a1335, #1e1456);
    width: 100vw;
    height: 100vh;
    z-index: 100000;
}
.fade-leave-active {
  transition: opacity .7s;
}
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

// -----------------------------------------------
//
// -----------------------------------------------

@include media('<tablet') {
    .title {
        font-size: 140%;
    }
}

@include media('>=480px') {
    .lifeskills {
        justify-content: center;
        max-width: 70em;
    }
}

// -----------------------------------------------
// 2 column layout with scaling
// -----------------------------------------------

@include media('<480px') {
    $gap: 2em;
    $item-width: 12em;

    .scrollContainer {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .lifeskills {
        width: 100%;
        max-width: $item-width * 2 + $gap;
        justify-content: space-between;
    }

    .Item {
        // width: calc(50% - #{$gap / 2});
        margin-left: 0;
        margin-right: 0;
    }
}
</style>
